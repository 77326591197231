export const firebaseConfig = {
    apiKey: "AIzaSyD4X2ixYuZLvlgLRaevyoSKXdROj3wHr-8",
    authDomain: "omnicure-staging.firebaseapp.com",
    databaseURL: "https://omnicure-staging.firebaseio.com",
    projectId: "omnicure-staging",
    storageBucket: "omnicure-staging.appspot.com",
    messagingSenderId: "983814465722",
    appId: "1:983814465722:web:80d21ea182c925e4eaba83",
    measurementId: "G-P3ZRLTHK4W",
    speechToTextURL: "https://streaming-speech-to-text-dot-omnicure-staging.uc.r.appspot.com"
  };